<template>
    <v-container>
        <v-row>
            <v-col v-if="improvement">
                <h1 class="text-h1">Verbeterplan bewerken #{{ improvement.number }}</h1>
                <h2 class="text-h2">
                    {{ improvement.title }}
                </h2>
            </v-col>
        </v-row>

        <v-form
            v-if="improvement"
            ref="form"
            v-model="isValid"
            class="pb-3"
        >
            <v-row>
                <v-col cols="12" md="6" class="pb-0">
                    <v-text-field
                        v-model="editedImprovement.title"
                        label="Omschrijving"
                        required
                        :rules="requiredRules"
                        :disabled="isSubmitting"
                        outlined
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" class="pb-0">
                    <v-select
                        :items="projects"
                        v-model="editedImprovement.project"
                        :disabled="isSubmitting"
                        label="Project"
                        outlined
                        item-value="id"
                    >
                        <template slot="selection" slot-scope="data">
                            {{ data.item.project_no }} - {{ data.item.name }}
                        </template>
                        <template slot="item" slot-scope="data">
                            {{ data.item.project_no }} - {{ data.item.name }}
                        </template>
                    </v-select>
                </v-col>
                <v-col cols="12" md="6" class="pb-0">
                    <v-select
                        :items="departments"
                        v-model="editedImprovement.reportedByDepartment"
                        :disabled="isSubmitting"
                        label="Ingediend door afdeling"
                        outlined
                    ></v-select>
                </v-col>
                <v-col cols="12" md="6" class="pb-0">
                    <v-select
                        :items="users"
                        v-model="editedImprovement.assignedUser"
                        :disabled="isSubmitting"
                        clearable
                        label="Ondergebracht bij persoon"
                        outlined
                        item-text="title"
                        item-value="id"
                    ></v-select>
                </v-col>
<!--                <v-col cols="12" md="6" class="pb-0">-->
<!--                    <v-menu-->
<!--                        ref="menu"-->
<!--                        v-model="menu"-->
<!--                        :close-on-content-click="false"-->
<!--                        transition="scale-transition"-->
<!--                        offset-y-->
<!--                        max-width="290px"-->
<!--                        min-width="auto"-->
<!--                    >-->
<!--                        <template v-slot:activator="{ on, attrs }">-->
<!--                            <v-text-field-->
<!--                                v-model="formattedTerugkopIndiener"-->
<!--                                label="Terugkop indiener"-->
<!--                                persistent-hint-->
<!--                                prepend-icon="fal fa-calendar"-->
<!--                                v-bind="attrs"-->
<!--                                @blur="terugkopIndiener = parseDate(formattedTerugkopIndiener)"-->
<!--                                v-on="on"-->
<!--                                :disabled="isSubmitting"-->
<!--                            ></v-text-field>-->
<!--                        </template>-->
<!--                        <v-date-picker-->
<!--                            v-model="terugkopIndiener"-->
<!--                            no-title-->
<!--                            @input="menu = false"-->
<!--                        ></v-date-picker>-->
<!--                    </v-menu>-->
<!--                </v-col>-->
                <v-col cols="12" md="6" class="pb-0">
                    <v-textarea
                        label="Wat is het probleem?"
                        v-model="editedImprovement.problem"
                        required
                        :rules="requiredRules"
                        :disabled="isSubmitting"
                        outlined
                    ></v-textarea>
                </v-col>
                <v-col cols="12" md="6" class="pb-0">
                    <v-textarea
                        label="Wat is het doel?"
                        v-model="editedImprovement.purpose"
                        required
                        :rules="requiredRules"
                        :disabled="isSubmitting"
                        outlined
                    ></v-textarea>
                </v-col>
                <v-col cols="12" md="6" class="pb-0">
                    <v-textarea
                        label="Wat is de oplossing?"
                        v-model="editedImprovement.solution"
                        required
                        :rules="requiredRules"
                        :disabled="isSubmitting"
                        outlined
                    ></v-textarea>
                </v-col>
                <v-col cols="12" md="6" class="pb-0">
                    <v-textarea
                        label="Hoe gaan we dit borgen?"
                        v-model="editedImprovement.secure"
                        required
                        :rules="requiredRules"
                        :disabled="isSubmitting"
                        outlined
                    ></v-textarea>
                </v-col>
                <v-col cols="12" md="12" class="pb-0">
                    <v-file-input
                        v-if="editedImprovement.image || (editedImprovement.image === null && editedImprovement.attachment === null)"
                        label="Bijlage"
                        v-model="editedImprovement.image"
                        prepend-icon="fal fa-paperclip"
                    ></v-file-input>
                    <v-file-input
                        v-else
                        label="Bijlage"
                        v-model="editedImprovement.attachment"
                        prepend-icon="fal fa-paperclip"
                    ></v-file-input>
                </v-col>
                <v-col cols="12">
                    <v-file-input
                        label="Extra afbeeldingen"
                        v-model="editedImprovement.extraImages"
                        :value="editedImprovement.image ? editedImprovement.image.name : null"
                        :multiple="true"
                        prepend-icon="fal fa-camera"
                        accept="image/*"
                    ></v-file-input>
                    <v-file-input
                        label="Extra bijlagen"
                        v-model="editedImprovement.extraFiles"
                        :multiple="true"
                        prepend-icon="fal fa-paperclip"
                    ></v-file-input>
                </v-col>
            </v-row>
            <v-btn
                class="btn-decorative"
                :disabled="!isValid || isSubmitting"
                :loading="isSubmitting"
                @click="submit"
                elevation="0"
            >
                <v-icon
                    small
                    class="mr-2"
                >
                    fal fa-arrow-right
                </v-icon>
                Indienen
            </v-btn>
        </v-form>
        <hr />
        <BackButton :route="{name: 'ImprovementDetail'}" />
    </v-container>
</template>

<script>
import BackButton from "../components/BackButton";
import {mapState} from "vuex";

export default {
    name: "ImprovementEdit",
    components: {
        BackButton,
    },
    data: () => ({
        improvement: null,
        editedImprovement: null,
        menu: false,
        isValid: false,
        isSubmitting: false,
        title: null,
        project: null,
        assignedUser: null,
        problem: null,
        purpose: null,
        solution: null,
        secure: null,
        department: null,
        extraImages: null,
        extraFiles: null,
        image: null,
        requiredRules: [
            v => !!v || 'Dit veld is verplicht',
        ],
        departments: [
            'Administratie',
            'Algemeen',
            'Calculatie',
            'Engineering',
            'Kopersbeheer',
            'Management Team',
            'Materieeldienst',
            'Projectvoorbereiding',
            'ICT',
            'Service Nazorg',
            'Projectontwikkeling',
            'Secretariaat',
            'Uitvoering',
            'Werkgroep',
            'Personeelszaken',
            'Ondernemingsraad',
            'Personeelsvereniging',
            'Stuurgroep Newforma',
            'Werkgroep PSA',
            'Gereedschapscommissie',
            'Team BMJ',
        ],
    }),
    computed: {
        users () {
            let users = JSON.parse(JSON.stringify(this.$store.state.users.users));
            users.sort((a, b) => a.title.localeCompare(b.title));
            return users;
        },
        // terugkopIndiener: {
        //     get() {
        //         return this.editedImprovement.terugkopIndiener.substring(0, 10);
        //     },
        //     set(val) {
        //         this.editedImprovement.terugkopIndiener = val;
        //     }
        // },
        // formattedTerugkopIndiener() {
        //     return  this.formatDate(this.terugkopIndiener)
        // },
        ...mapState({
            projects: state => state.projects.projects,
        }),
    },
    mounted() {
        this.loadImprovement();
        this.$store.dispatch('getProjects')
        this.$store.dispatch('getUsers')

    },
    methods: {
        loadImprovement() {
            let number = this.$route.params.number;
            this.$store.dispatch('getImprovementDetail', { number: number }).then(improvement => {
                this.improvement = improvement;
                this.editedImprovement = JSON.parse(JSON.stringify(this.improvement));
            })
        },
        formatDate (date) {
            if (!date) return null
            let dateFormatted = date.substring(0,10)
            const [year, month, day] = dateFormatted.split('-')
            return `${day}-${month}-${year}`
        },
        parseDate (date) {
            if (!date) return null

            const [day, month, year] = date.split('-')
            return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
        },
        submit() {
            if (!this.$refs.form.validate()) {
                return;
            }

            let formData = new FormData();
            formData.set('title', this.editedImprovement.title);
            formData.set('project', this.editedImprovement.project);
            formData.set('assignedUser', this.editedImprovement.assignedUser);
            formData.set('problem', this.editedImprovement.problem);
            formData.set('purpose', this.editedImprovement.purpose);
            formData.set('solution', this.editedImprovement.solution);
            formData.set('secure', this.editedImprovement.secure);
            formData.set('reportedByDepartment', this.editedImprovement.reportedByDepartment);
            // formData.set('terugkopIndiener', this.editedImprovement.terugkopIndiener)

            if (this.editedImprovement.image || this.editedImprovement.attachment) {
                formData.set('image', this.editedImprovement.image ?? this.editedImprovement.attachment);
            } else if (this.editedImprovement.image || this.editedImprovement.attachment) {
                formData.set('image', 'existing');
            } else {
                formData.set('image', null);
            }

            if (this.editedImprovement.extraImages && this.editedImprovement.extraImages.length > 0) {
                this.editedImprovement.extraImages.forEach((image, index) => {
                    formData.set('extraImages['+index+']', image);
                });
            } else if (this.editedImprovement.extraImages.length > 0) {
                formData.set('extraImages', 'existing');
            } else {
                formData.set('extraImages', null);
            }

            if (this.editedImprovement.extraFiles && this.editedImprovement.extraFiles.length > 0) {
                this.editedImprovement.extraFiles.forEach((image, index) => {
                    formData.set('extraFiles['+index+']', image);
                });
            } else if (this.editedImprovement.extraFiles.length > 0) {
                formData.set('extraFiles', 'existing');
            } else {
                formData.set('extraFiles', null);
            }

            this.isSubmitting = true;

            this.$store.dispatch('updateImprovement', {improvement: this.editedImprovement, formData}).then(improvement => {
                this.$router.push({name: 'ImprovementDetail', params: {number: improvement.number}});
            });
        },
    },
}
</script>

<style scoped>

</style>
